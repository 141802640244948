
import AthenaAlgorithm from "./v1/AthenaAlgorithm";

export default class SearchAlgorithm {

    /**
     * In this function, you need to choose which algorithm you wanna use
     * @param {SearchIndex[]} indexList 
     * @returns {SearchIndex[]}
     */
    static run(indexList = [], query) {
        return AthenaAlgorithm.run(indexList, query);
    }
}