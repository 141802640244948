export default class AthenaAlgorithm {
  /**
   * 
   * @param {SearchIndex[]} indexList
   */
  static run(indexList = [], query) {
    const queryWords = query.toLowerCase().split(' ');
    indexList = indexList.map((item) => {
      item.searchScore = AthenaAlgorithm.calculateRelevance(item, queryWords);
      return item;
    });

    // Sort results by relevance score in descending order
    return indexList
      .filter(result => result.searchScore > 0)
      .sort((a, b) => b.searchScore - a.searchScore);
  }

  /**
   * 
   * @param {SearchIndex} item
   * @param {str} queryWords
   */
  static calculateRelevance(item, queryWords) {
    let score = 0;

    if (item.title.trim().length < 60) {
      return score;
    }
    if (item.description.trim().length < 60) {
      return score;
    }

    const title = item.title.toLowerCase();
    const description = item.description.toLowerCase();
    const url = item.url;

    queryWords.forEach((word) => {
      if (title.includes(word)) {
        const occurrences = (title.match(new RegExp(word, 'g')) || []).length;
        score += occurrences * word.length; // Weight by word length
      }
      if (description.includes(word)) {
        const occurrences = (description.match(new RegExp(word, 'g')) || []).length;
        score += occurrences * word.length; // Weight by word length
      }
      if (url.includes(word)) {
        const occurrences = (url.match(new RegExp(word, 'g')) || []).length;
        score += occurrences * word.length; // Weight by word length
      }

      // Calculate Levenshtein distance and adjust the score
      score += this.getLevenshteinScore(word, title, description, url);
    });

    return score;
  }

  /**
   * Calculates Levenshtein score for a word against title, description, and URL
   * @param {string} word
   * @param {string} title
   * @param {string} description
   * @param {string} url
   * @returns {number} The calculated score
   */
  static getLevenshteinScore(word, title, description, url) {
    let score = 0;

    score += Math.max(0, word.length - this.levenshteinDistance(word, title));
    score += Math.max(0, word.length - this.levenshteinDistance(word, description));
    score += Math.max(0, word.length - this.levenshteinDistance(word, url));

    return score;
  }

  /**
   * Calculates the Levenshtein distance between two strings
   * @param {string} a
   * @param {string} b
   * @returns {number} The Levenshtein distance
   */
  static levenshteinDistance(a, b) {
    const matrix = Array.from({ length: a.length + 1 }, () => []);

    for (let i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }

    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        if (a[i - 1] === b[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            matrix[i][j - 1] + 1,     // insertion
            matrix[i - 1][j] + 1      // deletion
          );
        }
      }
    }

    return matrix[a.length][b.length];
  }

}
